import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
import 'popper.js';
import 'bootstrap';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
import Slider from 'bootstrap-slider';

document.addEventListener("DOMContentLoaded", () => {
	console.info('%cWebsite made with \u2665 by Stereobrand','font-size:11px;font-weight:bold');
	console.info('%chttps://www.stereobrand.de','font-size:11px');
});

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
const swiperProducts = new Swiper('.swiper-products', {
	slidesPerView: 2,
	centeredSlides: true,
	loop: true,
	spaceBetween: 30,
	pagination: {
			el: '.swiper-pagination',
			clickable: true,
	},
	navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
	},
});

const swiperHeaders = new Swiper('.swiper-autoplay', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
	  crossFade: true
	},
	autoplay: true,
	loop: true,
	pagination: {
			el: '.swiper-pagination',
			clickable: true,
	},
});

// jquery based js
(function($) {
	//dom-ready
	$(function() {
		// init bootstrap tooltips and popovers
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();

		// init bootstrap-slider
		const bsSlider = new Slider('[data-provide="slider"]');

		// make radio buttons de-selectable
		$('input[type=radio]').each(function(){
			$(this).attr('data-value', $(this).prop('checked'));
		});
		$('input[type=radio]').on('click', function(){
			console.debug($(this).attr('data-value'));
			if ($(this).attr('data-value') == 'true') {
				$(this).prop('checked', false);
				$(this).attr('data-value', 'false');
				return false;
			} else {
				$(this).attr('data-value', 'true');
			}
		});

		// change icon navigation on scroll
		if ($('#productheader').length) {
			var offset = $('content').offset();
			var delta = offset.top - $('body > header').height();
			$(window).scroll(function() {
				var scroll = $(window).scrollTop();
				if (scroll >= delta) {
					$('#productheader').removeClass('d-none');
				} else {
					$('#productheader').addClass('d-none');
				}
			});
		}
	});
}(jQuery));
